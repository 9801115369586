import {IconButton, InputBase, InputBaseComponentProps, makeStyles} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import classNames from 'classnames'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex'
  },
  input: {
    paddingLeft: 15,
    color: 'rgb(0, 55, 77)',
    fontWeight: 500,
    '&::-webkit-input-placeholder': {
      color: 'rgb(0, 55, 77)',
      fontWeight: 500
    }
  },
  searchButton: {
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none'
    }
  }
}))

interface Props {
  className?: string
  inputProps?: InputBaseComponentProps
  iconProps?: any
  placeholder: string
  value?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

/* eslint-disable jsx-a11y/no-autofocus */
const Searchfield: React.FC<Props> = ({
  className: classNameProp,
  inputProps,
  iconProps,
  placeholder,
  ...otherProps
}) => {
  const classes = useStyles()

  return (
    <div className={classNames(classes.root, classNameProp)} {...otherProps}>
      <InputBase
        placeholder={placeholder}
        autoFocus
        classes={{
          input: classes.input
        }}
        inputProps={inputProps}
      />
      <IconButton className={classes.searchButton} {...iconProps}>
        <SearchIcon />
      </IconButton>
    </div>
  )
}

export default Searchfield
