import {makeStyles} from '@material-ui/core'
import {PersonOutline} from '@material-ui/icons'
import React, {SyntheticEvent} from 'react'

import HproduceIconButton from '../Shell/HproduceIconButton'

interface AccountButtonProps {
  onClick: (event: SyntheticEvent<EventTarget>) => void
}

const useStyles = makeStyles((theme) => ({
  fill: {
    fill: theme.palette.background.default
  },
  icon: {
    fontSize: 16
  }
}))

const AccountButton: React.FC<AccountButtonProps> = ({onClick}) => {
  const classes = useStyles()

  return (
    <HproduceIconButton onClick={onClick} dataTestId="account-menu-icon">
      <PersonOutline className={classes.icon} fill={classes.fill} />
    </HproduceIconButton>
  )
}

export default AccountButton
