import {Box, Link, Popover} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import React, {SyntheticEvent, useState} from 'react'
import {useTranslation} from 'react-i18next'

import AccountButton from './AccountButton'
import AccountDropdownLogoutButton from './AccountDropdown.LogoutButton'
import {Action} from './AccountDropdown.types'
import {Locales, LanguageSwitcher} from './LanguageSwitcher'
import Typography from './Typography'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2.5),
    width: 360,
    background: 'rgb(247, 247, 247)'
  },

  accountButton: {
    padding: theme.spacing(2)
  },

  box: {
    padding: theme.spacing(2)
  },

  email: {
    overflowWrap: 'break-word'
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main
  }
}))

interface Content {
  header?: string | null
  body: any
}

interface Profile {
  name: string
  email: string
}

interface AccountDropdownProps {
  actions: Action
  logoutButtonText: string
  profile?: Profile
  content?: Content
  locales?: Locales
  userPage?: string
  userAdminLink?: string
  userAdminLinkLabel?: string
  userAdminLinkOnClick?: () => void
  customerPanelProfile?: React.ReactNode
}

const AccountDropdown: React.FC<AccountDropdownProps> = ({
  actions,
  logoutButtonText,
  profile,
  content,
  locales,
  userPage,
  userAdminLink,
  userAdminLinkLabel,
  userAdminLinkOnClick,
  customerPanelProfile
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>()
  const {t} = useTranslation()
  const handleClick = (event: SyntheticEvent<EventTarget>) =>
    setAnchorEl(event.currentTarget as HTMLInputElement)

  const handleClose = () => setAnchorEl(null)
  return (
    <div className={classes.accountButton}>
      <AccountButton onClick={(e) => handleClick(e)} />
      <Popover
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{paper: classes.paper}}
        data-test-id="account-menu-popover"
      >
        {profile ? (
          <div className={classes.box}>
            <Typography
              variant="body2"
              color="textPrimary"
              data-test-id="account-menu-profile-name"
            >
              {profile.name}
            </Typography>
            <Typography
              className={classes.email}
              variant="body2"
              customColor="textPrimarySoft"
              data-test-id="account-menu-profile-email"
            >
              {profile.email}
            </Typography>
            {userPage && (
              <Link href={userPage}>{t('accountDropdown.goToUserPage', 'Go to user page')}</Link>
            )}
          </div>
        ) : null}
        {content ? (
          <div className={classes.box}>
            {content.header ? (
              <Typography
                variant="body2"
                color="textPrimary"
                data-test-id="account-menu-profile-accounts"
              >
                {content.header}
              </Typography>
            ) : null}
            {content.body}
          </div>
        ) : null}
        {userAdminLink ? (
          <div className={classes.box}>
            <Link
              underline="hover"
              rel="noopener"
              target="_blank"
              color="textPrimary"
              variant="body2"
              href={userAdminLink}
              data-test-id="account-userAdminLink"
              onClick={userAdminLinkOnClick}
            >
              <Box display="flex" alignItems="center" gridGap={8}>
                {userAdminLinkLabel ?? 'Manage users'} <OpenInNewIcon fontSize="small" />
              </Box>
            </Link>
          </div>
        ) : null}
        {customerPanelProfile}
        {locales ? (
          <LanguageSwitcher
            locales={locales}
            onSelectLanguage={(lang: string) => {
              if (actions.selectLanguage) {
                actions.selectLanguage(lang)
              }
            }}
          />
        ) : null}
        <AccountDropdownLogoutButton text={logoutButtonText} actions={actions} />
      </Popover>
    </div>
  )
}

export default AccountDropdown
