import {
  FormHelperText,
  makeStyles,
  MenuItem,
  SelectProps,
  TextField,
  TextFieldProps,
  Theme,
  Typography
} from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  rowTitle: {
    marginLeft: '10px',
    color: theme.palette.secondary.main
  }
}))

export interface SelectGroupedOption {
  label: string
  subLabel?: string
  value?: string
  disabled?: boolean
}

interface Props {
  options: SelectGroupedOption[]
  caption?: string
}

const SelectGrouped: React.FC<Props & TextFieldProps> = ({
  caption,
  options,
  value,
  onChange,
  helperText,
  ...props
}) => {
  const classes = useStyles()

  const hasSubLabels = options.some((option) => option.subLabel)

  const selectProps: Partial<SelectProps> = hasSubLabels
    ? {
        // needed to show just the label and not the sublabel in closed-selected state
        renderValue: (value) => {
          if (typeof value !== 'string') {
            return ''
          }
          const matchingOption = options.find((option) => option.value === value)
          return matchingOption?.label || ''
        }
      }
    : {}
  return (
    <>
      <TextField
        select
        onChange={(e) => onChange && onChange(e)}
        value={value}
        helperText={helperText}
        SelectProps={selectProps}
        {...props}
      >
        {options.map((option) => {
          if (!option.value) {
            return (
              <div className={classes.rowTitle} key={option.label}>
                {option.label}
              </div>
            )
          }

          if (option.subLabel) {
            return (
              <MenuItem
                // <div> is needed instead of <li> because of formatting
                // @ts-expect-error MUI types don't recognize component prop
                component="div"
                key={option.value}
                value={option.value}
                disabled={option.disabled}
              >
                <div>
                  <Typography>{option.label}</Typography>
                  <Typography variant="caption" style={{whiteSpace: 'pre-line'}}>
                    {option.subLabel}
                  </Typography>
                </div>
              </MenuItem>
            )
          }

          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          )
        })}
      </TextField>
      {caption && <FormHelperText style={{whiteSpace: 'pre-line'}}>{caption}</FormHelperText>}
    </>
  )
}

export default SelectGrouped
